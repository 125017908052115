import axios from 'axios'
import { BigBannerUM, EventBannerUM } from '@/model/ui/banner-ui-model.js'
import { EventProductUM, ProductUM } from '@/model/ui/product-ui-model.js'

export default {
  // ? 大電視
  async GetBigBanner () {
    const url = `${process.env.VUE_APP_API}/api/widgets/banner`
    const res = await axios.get(url)
    // if (res.data)
    const data = res.data.info
    const rtnData = data.map((item) => {
      const d = new BigBannerUM()
      d.name = item.name
      d.image = item.image
      d.targetUrl = item.targetUrl
      return d
    })
    return rtnData
  },
  // ? 小電視
  async GetEventBanner (eventCode) {
    const url = `${process.env.VUE_APP_API}/api/widgets/activitybanner?code=${eventCode}`
    const res = await axios.get(url)
    if (res.data.rtnCode !== 0) {
      return []
    }
    const data = res.data.info
    const rtnData = data.map((item) => {
      const d = new EventBannerUM()
      d.subCode = item.subCode
      d.image = item.image
      d.targetUrl = item.targetUrl
      return d
    })
    return rtnData
  },
  // ? 活動商品
  async GetEventProduct (eventCode) {
    const url = `${process.env.VUE_APP_API}/api/product/eventproducts?code=${eventCode}`
    const res = await axios.get(url)
    const data = res.data.info
    const rtnData = data.map((item) => {
      const d = new EventProductUM()
      d.subCode = item.code
      d.name = item.name
      d.title = item.title
      d.description = item.description
      d.videoLink = item.link
      if (item.products) {
        d.products = item.products.map((item2) => {
          const d2 = new ProductUM()
          d2.Id = item2.productId
          d2.Name = item2.productName
          d2.Price = item2.price
          d2.MSRP = item2.oldPrice
          d2.Image = item2.productImage
          d2.Stock = item2.stock
          return d2
        })
      }
      return d
    })
    return rtnData
  }
}
