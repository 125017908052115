// * 大電視
export class BigBannerUM {
    name;// string
    image;// string
    targetUrl;// string
}

// * 活動廣告
export class EventBannerUM {
    subCode;// string
    image;// string
    targetUrl;// string
}
